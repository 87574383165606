import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const Contact = () => (
  <Layout>
    <SEO title="Kontakt" />
    <h3>Kontakt</h3>
    <p>
      Teenusepakkuja: Honeymarket OÜ
      <br />
      Tel.: +372 5451 3000
      <br />
      E-mail: info@honeymarket.com
    </p>
    <p>
      Kaart:{' '}
      <a
        title="Maa-amet"
        href="http://geoportaal.maaamet.ee"
        target="_blank"
        rel="noopener noreferrer"
      >
        Maa-amet
      </a>
      <br />
      Mesilad:{' '}
      <a
        title="PRIA Põllumajandusloomade register"
        href="https://kls.pria.ee/kaart/"
        target="_blank"
        rel="noopener noreferrer"
      >
        PRIA põllumajandusloomade register
      </a>
    </p>
    <p>
      mesi.ee kuvab põllumajandusloomade registri{' '}
      <a href="https://avaandmed.eesti.ee/datasets/pollumajandusloomade-register">
        avaandmeid
      </a>
      . Andmete lisamiseks, muutmiseks või eemaldamiseks palume pöörduda vastava
      registri poole.
    </p>
  </Layout>
)

export default Contact
